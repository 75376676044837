import useAppInitData from "./useAppInitData";
import useAsyncFetch from "./useAsyncFetch";
import useIsGuest from "./useIsGuest";

const useLogout = () => {
	const { data } = useAppInitData();
	const showWelcomePopupCookie = useCookie("showWelcomePopup");
	const loginFlowCookie = useCookie("isLoginFlow");
	const presetPackageModalCookie = useCookie("presetPackageModal");
	const isGuestCookie = useCookie("isGuest");
	const isGuest = useIsGuest();

	const { execute: logoutUser } = useAsyncFetch({
		path: "/rest/logout/",
		method: "get",
		fetchOptions: {
			onResponse: ({ response }) => {
				if (response._data.success) {
					if (data.value) {
						data.value.isGuest = true;
						isGuest.value = true;
					}
					showWelcomePopupCookie.value = null;
					loginFlowCookie.value = null;
					presetPackageModalCookie.value = null;
					isGuestCookie.value = "true";
					window?.$store?.$cash?.removeProfile();
				}
			}
		},
		options: {
			server: false,
			lazy: true,
			immediate: false
		}
	});

	return { logoutUser };
};

export default useLogout;
